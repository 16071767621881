.simple-keyboard {
    border: 0;
}

.ui-keyboard-button {
    height: 20.78px !important;
    min-width: 20.78px !important;
    font-size: 13.86px !important;
    line-height: 13.86px !important;
    margin: 0 !important;
}

.ui-keyboard-keyset{
    line-height: 10px !important;
}

.ui-keyboard-empty {
    border: 0 !important;
}
.euridVirtualKeyboard {
    border-radius: 0;
}